<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                ADUAN TATA RUANG
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    Aduan Masyarakat
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="col-sm-12">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="row">
            <div class="help-block text-center wow fadeInLeft">
                Silahkan masukkan identitas dan aduan, memasukkan aduan yang hendak disampaikan, silahkan mengisikan
                data
                identitas diri secara lengkap dan tepat. Kekeliruan mengisikan data identitas mengakibatkan admin tidak
                bisa
                memberikan tindak lanjut dari aduan yang disampaikan.
            </div>
            <div class="col-sm-12">&nbsp;</div>
            <div class="col-sm-12">&nbsp;</div>
            <div class="col-sm-6 float-left pb-2 wow fadeInDown">
                <label>Nama <small class="text-info"> (*wajib diisi)</small></label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                    </div>
                    <input type="text" class="form-control form-control-sm" id="nama" name="nama" placeholder="Nama"
                        v-model="nama">
                </div>
            </div>
            <div class="col-sm-6 float-left pb-2 wow fadeInDown">
                <label>Nomor Telepon <small class="text-info"> (*wajib diisi)</small></label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="number" class="form-control form-control-sm" id="nohp" name="nohp"
                        placeholder="Nomor Telepon" v-model="nohp">
                </div>
            </div>
            <div class="col-sm-6 float-left pb-2 wow fadeInDown">
                <label>Alamat <small class="text-info"> (*wajib diisi)</small></label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="far fa-address-card"></i></span>
                    </div>
                    <input type="text" class="form-control form-control-sm" id="alamat" name="alamat"
                        placeholder="Alamat" v-model="alamat">
                </div>
            </div>
            <div class="col-sm-6 float-left pb-2 wow fadeInDown">
                <label>Aduan <small class="text-info"> (*wajib diisi)</small></label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="far fa-sticky-note"></i></span>
                    </div>
                    <input type="text" class="form-control form-control-sm" id="aduan" name="aduan" placeholder="Aduan"
                        v-model="aduan">
                </div>
            </div>
            <div class="col-sm-6 float-left pb-2 wow fadeInDown">
                <label>Kode Keamanan</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-clipboard-list"></i></span>
                    </div>
                    <input type="text" class="form-control form-control-sm" id="capcha" name="capcha"
                        placeholder="Kode Keamanan" v-model="capcha" disabled>
                </div>
            </div>
            <div class="col-sm-6 float-left pb-2 wow fadeInDown">
                <label>Ulangi Kode Keamanan</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="far fa-clone"></i></span>
                    </div>
                    <input type="text" class="form-control form-control-sm" id="capchainput" name="capchainput"
                        placeholder="" v-model="capchainput" style="text-transform:uppercase">
                </div>
            </div>
        </div>
    </div>
    <div class="lockscreen-item wow fadeInLeft" style="background: transparent;">
        <div class="input-group">
            <div class="custom-control custom-switch custom-switch-on-primary">
                <input type="checkbox" class="custom-control-input" id="customSwitchAuth">
                <label class="custom-control-label" for="customSwitchAuth" @click="rubahisi()"
                    style="font-size: small;">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi Pengguna Manusia</span>
                    <span v-else>Human User Authentication</span>
                </label>
            </div>
        </div>
        <div class="help-block text-center">&nbsp;</div>
        <button class="btn btn-danger2 btn-block text-white" placeholder="Kirim" data-toggle="tooltip"
            data-placement="left" title="Kirim aduan.." style="color: black;" v-on:click="aduanAct"
            :disabled="showHuman == false">
            <b><i class="fas fa-mail-bulk"></i>
                Kirim
            </b>
        </button>
    </div>
    <!-- /.lockscreen-item -->

    <div class="help-block text-center">&nbsp;</div>
    <div class="content-header">
        <div class="col-sm-12">&nbsp;</div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            nama: "", nohp: "", alamat: "", aduan: "", capcha: this.makeid(5), capchainput: "",
            showHuman: false,
            showPassword: false,
            bahasa: localStorage.getItem("bahasasistem"),
        };
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        async aduanAct() {
            const data = {
                nama: this.nama,
                nohp: this.nohp,
                alamat: this.alamat,
                aduan: this.aduan
            };
            if (this.nama == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("nama").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.nohp == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("nohp").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.alamat == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("alamat").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.aduan == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("aduan").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.capchainput == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.capchainput.toUpperCase() != this.capcha) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Kode Keamanan Tidak Sama..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer SiKerenGPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2j2a098002",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("parastapainnovationMasyarakat-aduan", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        document.getElementById("nama").focus();
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        return false;

                    }
                    else {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'success',
                            title: Response.data.message,
                        });
                        this.nama = '';
                        this.nohp = '';
                        this.alamat = '';
                        this.aduan = '';
                        this.capchainput = '';
                        this.capcha = this.makeid(5);
                        document.getElementById("nama").focus();
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;

                        return false;
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    document.getElementById("nama").focus();
                    document.getElementById('customSwitchAuth').checked = false;
                    this.showHuman = false;
                    return false;
                }
            )
        },
    },
    mounted() {
        try {
            document.getElementById("nama").focus();
            this.showHuman = false;
            this.JustClearlocalStorage();
        } catch (error) {

        }
        this.halamanloading = false;
    },
}
</script>

<style></style>