<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center" style="opacity: 0.5;">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <center>
        <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Cetak</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-justify">
                        <p>
                        <div class="col-sm-12">
                            <iframe src="" id="iframefile" @load="loadpdf()" style="width: 100%;height: 75vh;"></iframe>
                        </div>
                        </p>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-danger2 btn-block text-white" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
    </center>
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                CETAK / CARI INFORMASI TATA RUANG
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    Layanan Cetak / Cari Informasi Tata Ruang
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="col-sm-12">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="row">
            <div class="help-block text-center wow fadeInLeft">
                Silahkan masukkan kode registrasi yang dimiliki dan klik tombol cari kemudian dilanjutkan dengan cetak.
            </div>
            <div class="col-sm-12">&nbsp;</div>
            <div class="col-sm-12">&nbsp;</div>
            <div class="col-sm-4">&nbsp;</div>
            <div class="col-sm-4">
                <div class="col-sm-12 text-center pb-2 wow fadeInDown">
                    <label>Kode Registrasi <small class="text-info"> (*wajib diisi)</small></label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-sm" id="koderegistrasi"
                            name="koderegistrasi" placeholder="Kode Registrasi" v-model="koderegistrasi">
                    </div>
                </div>
                <div class="col-sm-12 text-center pb-2 wow fadeInDown">
                    <label>Kode Keamanan</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-clipboard-list"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-sm" id="capcha" name="capcha"
                            placeholder="Kode Keamanan" v-model="capcha" disabled>
                    </div>
                </div>
                <div class="col-sm-12 text-center pb-2 wow fadeInDown">
                    <label>Ulangi Kode Keamanan</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="far fa-clone"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-sm" id="capchainput" name="capchainput"
                            placeholder="Ulangi Kode Keamanan" v-model="capchainput" style="text-transform:uppercase">
                    </div>
                </div>
            </div>
            <div class="col-sm-4">&nbsp;</div>
        </div>
    </div>
    <div class="lockscreen-item wow fadeInLeft" style="background: transparent;">
        <div class="input-group">
            <div class="custom-control custom-switch custom-switch-on-primary">
                <input type="checkbox" class="custom-control-input" id="customSwitchAuth">
                <label class="custom-control-label" for="customSwitchAuth" @click="rubahisi()"
                    style="font-size: small;">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi Pengguna Manusia</span>
                    <span v-else>Human User Authentication</span>
                </label>
            </div>
        </div>
        <div class="help-block text-center">&nbsp;</div>
        <button class="btn btn-danger2 btn-block text-white" placeholder="Kirim" data-toggle="tooltip"
            data-placement="left" title="Kirim aduan.." style="color: black;" v-on:click="CetakAct"
            :disabled="showHuman == false">
            <b><i class="fas fa-search"></i>
                Cari
            </b>
        </button>
    </div>
    <!-- /.lockscreen-item -->

    <div class="help-block text-center">&nbsp;</div>
    <div class="row px-5">
        <div class="col-sm-12 wow fadeInDown">
            <div class="card cardputih">
                <div class="card-header border-transparent">
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="col-sm-12 float-left pb-2">
                        <div class="table-responsive table-wrapper">
                            <table class="table m-0" id="tabeldata">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">No</th>
                                        <th style="width: 150px;">Nama</th>
                                        <th style="width: 150px;">Alamat</th>
                                        <th style="width: 150px;">Alamat Lokasi</th>
                                        <th style="width: 150px;">Rencana Kegiatan</th>
                                        <th style="width: 150px;">No Sertifikat</th>
                                        <th style="width: 150px;">Opsi</th>
                                    </tr>
                                </thead>
                                <template v-for="(datalist, urutlist) in datamaster" :key="urutlist">
                                    <tbody>
                                        <tr>
                                            <td>{{ urutlist + 1 }}</td>
                                            <td>
                                                {{ datalist.Nama }}
                                            </td>
                                            <td>
                                                {{ datalist.Alamat }}
                                            </td>
                                            <td>
                                                {{ datalist.AlamatLokasi }}
                                            </td>
                                            <td>
                                                {{ datalist.RencanaKegiatan }}
                                            </td>
                                            <td>
                                                {{ datalist.NoSertifikat }}
                                            </td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-primary3 btn-sm lebar2 text-white"
                                                    @click="prosesdata(datalist.Koderegistrasi)" data-toggle="tooltip"
                                                    data-placement="left" title="Cetak data..">
                                                    <i class="fas fa-print"></i> Cetak Data
                                                </button>
                                                <button type="button" class="btn btn-danger3 btn-sm lebar2 text-white"
                                                    @click="cetakdata(datalist.Koderegistrasi)" data-toggle="tooltip"
                                                    data-placement="left" title="Cetak Lampiran..">
                                                    <i class="fas fa-print"></i> Cetak Lampiran
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                                <tbody v-if="(datapesan == 'data kosong')">
                                    <tr>
                                        <td colspan="7">Isikan kode register</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-header">
        <div class="col-sm-12">&nbsp;</div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            koderegistrasi: "", capcha: this.makeid(5), capchainput: "",
            showHuman: false,
            showPassword: false,
            datamaster: [],
            datapesan: '',
        };
    },
    methods: {
        loadpdf(){
            this.halamanloading = false;
        },
        prosesdata(kode) {
            this.halamanloading = true;
            document.getElementById('iframefile').src = "";
            //alert(process.env.VUE_APP_URL_API + foldernya + '?f=' + filenya);
            document.getElementById('iframefile').src = process.env.VUE_APP_URL_API + "Parastapa-cetakpdf?kode=" + btoa(kode);
            $('#costumModaldisclaimer').modal('show');
        },
        cetakdata(kode) {
            this.halamanloading = true;
            document.getElementById('iframefile').src = "";
            //alert(process.env.VUE_APP_URL_API + foldernya + '?f=' + filenya);
            document.getElementById('iframefile').src = process.env.VUE_APP_URL_API + "Parastapa-maps?kode=" + btoa(kode);
            $('#costumModaldisclaimer').modal('show');
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        async CetakAct() {
            var md5Hash = CryptoJS.MD5(this.koderegistrasi.trim());
            const data = {
                koderegistrasi: md5Hash,
            };
            if (this.koderegistrasi == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("koderegistrasi").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.capchainput == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.capchainput.toUpperCase() != this.capcha) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Kode Keamanan Tidak Sama..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer SiKerenITRGPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2j2a098002",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("parastapainnovationMasyarakat-caridataitr", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        document.getElementById("koderegistrasi").focus();
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        this.halamanloading = false;
                        return false;

                    }
                    else {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'success',
                            title: Response.data.message,
                        });
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamaster = [];
                            this.datamaster = Response.data.content.data;
                        }
                        this.halamanloading = false;
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;

                        return false;
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    document.getElementById("koderegistrasi").focus();
                    document.getElementById('customSwitchAuth').checked = false;
                    this.showHuman = false;
                    this.halamanloading = false;
                    return false;
                }
            )
        },
    },
    mounted() {
        try {
            document.getElementById("koderegistrasi").focus();
            this.showHuman = false;
            this.JustClearlocalStorage();
        } catch (error) {

        }
        this.halamanloading = false;
    },
}
</script>

<style></style>