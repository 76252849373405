<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12 text-center wow fadeInDown">
                            <h5>Hello, Welcome {{ namauser }}, Selamat Datang di <br><img src="../../../assets/img/berandaNgumahNew.png"
                                alt="Admin Logo" class="animation__shake wow fadeInDown" style="opacity: .8;height: 135px;"></h5>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect
    },
    data() {
        return {
            halamanloading: true,
            showFilter: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kabkotuser: JSON.parse(localStorage.getItem("bdlsistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            
        }
    },
    methods: {
        toggleShow() {
            this.showFilter = !this.showFilter;
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.halamanloading = false;
        //this.ambildata();
    }
}
</script>
<style></style>